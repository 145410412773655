import React from 'react';
import HtmlParser from 'react-html-parser';
import {Link} from 'gatsby';
const ProductTile = (props) => {
	let tileSubHeading = HtmlParser(props.product.short_product_description);
	let weightManagementProgram = props.product.product_category === 'weightmanagementprogramme';
	return (
		<>
		{weightManagementProgram?
		<div className="w-full productTile programmeTile text-center">
			<Link to={props.product.link}>
				<img className="productTileFeatureImg block mx-auto mb-4"
					alt={props.product.title}
					src={props.gravURL + props.product.thumb}
					width="280px"
					height="418px"
				/>
			</Link>
				<h4 className="text-primary font-medium mb-2">{props.product.title}</h4>
				<p className="text-secondary text-xs">{tileSubHeading}</p>
			<Link to={props.product.link} className="darkButton button mt-2">Product Info</Link>	
			
		</div>
		:
		<div className="sm:w-6/12 md:w-4/12">
			<Link to={props.product.link} className="w-full block">
				<img className="productTileFeatureImg block mx-auto mb-4"
					alt={props.product.title}
					src={props.gravURL + props.product.thumb}
					width="280px"
					height="418px"
				/>
				<h4 className="text-primary font-medium mb-2">{props.product.title}</h4>
				<p className="text-secondary text-xs">{tileSubHeading}</p>
			</Link>
			<Link to={props.product.link} className="darkButton button mt-2">Product Info</Link>	
		</div>
		}
		</>
	);
}
export default ProductTile;