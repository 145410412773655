import React from 'react';
import ProductsPageLayout from './ProductsPageLayout'
import '../../Content/css/products.css';
import Helmet from 'react-helmet'
import {Link} from 'gatsby';
let gravURL = process.env.REACT_APP_GRAV_URL;
let siteURL = process.env.REACT_APP_APP_URL;

export default function ProductsPage(props) {
	let {title,keyWords,url,facebookTitle,facebookDesc,shareImg,googleDesc} = props.pageContext.head;
	return (
		<main>
			<Helmet>
				<title>Products | New Image Keto | Alpha Lipid™ SDII Programme</title>	
				{googleDesc&&<meta name="description" content={googleDesc}/>}
				{keyWords&& <meta name="keywords" content={keyWords.toString()} />}
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta property="og:url" content={url?siteURL + url:siteURL} />
				<meta property="og:title" content={facebookTitle?facebookTitle:title}/>
				<meta name="twitter:title" content={facebookTitle?facebookTitle:title}/>
				<meta property="og:description" content={facebookDesc?facebookDesc:googleDesc} />
				<meta name="twitter:description" content={facebookDesc?facebookDesc:googleDesc}/>
				<meta property="og:image" content={shareImg} />
				<meta property="og:image:url" content={shareImg}/>
				<meta name="twitter:image" content={shareImg}/>
				<meta name="twitter:card" content={shareImg}/>
			</Helmet>
			<div className="clearfix  text-center grid productsPageTitleWrapper">
				<section style={{paddingTop:'0px'}}>
					<div className="pageGrid text-center pageHeader">
						<h2 className="mainHeading">Products</h2>
						<h3 className="capitalize subHeading">
							<Link to="/recipes">REAL FOOD<span className="pipeContainer"> &ensp;|&ensp; </span></Link>
							<Link to="/the-science"><span className="pipeContainer"> </span>REAL SCIENCE<span className="pipeContainer"> &ensp;|&ensp; </span></Link>
							<Link to="/about-the-programme"><span className="pipeContainer"> </span>REAL RESULTS</Link>
						</h3>
					</div>
				</section>
				<ProductsPageLayout products={props.pageContext.pagesSD} gravURL={gravURL}/>
			</div>
		</main>
	);
}
