import React from 'react';
import ProductTile from './ProductTile'
export default function productsPageLayout(props) {
	let products = props.products.map((item, key)=>
		<ProductTile product={item} key={key} gravURL={props.gravURL}/>
	);
	return (
		<div className="pageGrid">
			<div className="my-10">
				<div className="xs:block sm:flex sm:flex-wrap">{products}</div>
			</div>
		</div>
	);
}

